import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Foerdermittelgeber from "./foerdermittelgeber"

import sortMarkdown from "../../../utils/sort-markdown"

const FoerdermittelgeberContainer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        markdown: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/foerdermittelgeber/" } }
        ) {
          edges {
            node {
              html
              frontmatter {
                image {
                  childImageSharp {
                    gatsbyImageData(quality: 90, height: 300, width: 300)
                  }
                }
                title
                order
              }
            }
          }
        }
      }
    `
  )

  const markdown = sortMarkdown(query.markdown.edges, ["order", "title"])

  return markdown.length > 0 ? <Foerdermittelgeber markdown={markdown} /> : null
}

export default FoerdermittelgeberContainer

/* eslint-disable react/no-danger */

import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFastForward, faSearch } from "@fortawesome/free-solid-svg-icons"

import "./foerdermittelgeber.css"

const Foerdermittelgeber = ({ markdown }) => (
  <section className="foerdermittelgeber" id="foerdermittelgeber">
    <h2>
      <FontAwesomeIcon icon={faFastForward} size="lg" />
      Gefördert durch
    </h2>
    <ul className="foerdermittelgeber__list">
      {markdown.map(foerdermittelgeber => (
        <li key={foerdermittelgeber.node.frontmatter.title}>
          {foerdermittelgeber.node.frontmatter.title && (
            <h3>{foerdermittelgeber.node.frontmatter.title}</h3>
          )}
          <div>
            {foerdermittelgeber.node.frontmatter.image && (
              <GatsbyImage
                alt={foerdermittelgeber.node.frontmatter.title}
                className="foerdermittelgeber__list-image"
                image={
                  foerdermittelgeber.node.frontmatter.image.childImageSharp
                    .gatsbyImageData
                }
              />
            )}
            <div dangerouslySetInnerHTML={{ __html: foerdermittelgeber.node.html }} />
          </div>
        </li>
      ))}
    </ul>
  </section>
)

Foerdermittelgeber.propTypes = {
  markdown: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          image: PropTypes.shape({}),
          title: PropTypes.string,
        }),
        html: PropTypes.node,
      }),
    })
  ).isRequired,
}

export default Foerdermittelgeber
